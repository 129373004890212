
import React from 'react';

const ProtectedComponent = () => {
  return (
    <div>
      <h2>Protected Page</h2>
      <p>This is a protected page. Only logged in users can see this.</p>
    </div>
  );
};

export default ProtectedComponent;
